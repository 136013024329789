import axios from 'axios';


const BASE_URL = "https://tomodachi-stage.herokuapp.com/"

export const getSignerWhiteList = async (address) => {
  try {
    const { data } = await axios.post(BASE_URL + 'whitelist', { address: address })
    console.log(data)
    return data
  } catch (err) {
    console.error(err)
    return false
  }
}


export const getSignerReserveList = async (address) => {
    try {
      const { data } = await axios.post(BASE_URL + 'reservelist', { address: address })
      console.log(data)
      return data
    } catch (err) {
      console.error(err)
      return false
    }
  }