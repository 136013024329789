import React, { useState } from "react"; 
import vector from "../../assets/images/vector.png";
import CompletedIcon from "../../assets/images/completed.png";
import "./style.css";
import PropagateLoader from "react-spinners/PropagateLoader";

const PendingTransaction = (props) => {
  const { transactionStatus, etherscanUrl } = props;
  const redirect = async()=>{
    if(etherscanUrl!==""){
      window.open(etherscanUrl)
    }
  }
  return (
    <div className="pending-transaction">
      <div>
        {transactionStatus ? (
          <PropagateLoader color="rgb(54, 215, 183)" loading={true} size={20} />
        ) : (
          <img src={CompletedIcon} alt="Loading" className="completed-icon" />
        )}
      </div>

      <div className="pendingTtraction-txt" >
        <span>
          {transactionStatus ? "Pending transaction" : " View on etherscan"}
        </span>{" "}
        <img src={vector} alt="Info Icon" onClick={redirect}/>
      </div>
    </div>
  );
};
export default PendingTransaction;
