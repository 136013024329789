import React from "react"; 
import "./style.css";

const Banner = (props) => {
  const { bannerImage } = props;
  return (
    <div className="banner-cnt">
      <img
        src={bannerImage}
        width="100%"
        height={"100%"}
      />
    </div>
  );
};
export default Banner;
