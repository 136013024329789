import React, { useState, useEffect, useLayoutEffect } from "react";
import ConnectButton from "../connectButton/connectButton";
import { useConnectedMetaMask, useMetaMask } from "metamask-react";
import { toast } from "react-toastify";
import AccountDetails from "../acountDetails/acountDetails";
import Mint from "../mint/mint.jsx";
import Banner from "../banner/banner";
import PendingTransaction from "../pendingTransaction/pendingTransaction";
import "./style.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import {
  providerHandler,
  publicMint,
  PUBLIC_MINT,
  reserveListCap,
  reserveListEndTime,
  reserveListMint,
  reserveListMintedd,
  RESERVE_MINT,
  whiteListCap,
  whiteListEndTime,
  whiteListMint,
  whiteListMintedd,
  WHITE_LIST_MINT,
  isWhiteList,
  isReserveList,
  isPublicList,
  PUBLIC_MINT_MAX,
  RESERVE_MINT_MAX,
  WHITE_MINT_MAX
} from "../../web3/contractInteraction";
import {
  getSignerWhiteList,
  getSignerReserveList,
} from "../../utils/backendApi";
import BannerImage from "../../assets/images/banner.png";
import PendingImage from "../../assets/images/gif2.gif";
import SuccessImage from "../../assets/images/gif1.gif";

const Home = () => {
  const { status, connect, account } = useMetaMask();
  const [accountName, setAccountName] = useState();
  const [mintCount, setMintCount] = useState(0);
  const [totalMinCount, setTotalMinCount] = useState(0.0);
  const [listType, setListType] = useState(0);
  const [signer, setSigner] = useState(false);
  // 0-> normal
  // 1-> whitelist
  // 2-> reserveList
  const [maxCount, setMaxCount] = useState(0);
  const [url, setUrl] = useState("");
  const [countCheck, setCountCheck] = useState(0);
  //const [disable, setDisable] = useState(false);
  const [mintButton, setMintButton] = useState(true);

  useEffect(() => {
    let firstLetters = account ? account.substring(0, 8) : "";
    let lastFiveLetters = account ? account.substring(account.length - 7) : "";
    setAccountName(firstLetters + "......." + lastFiveLetters);
  }, [account]);

  const [stateStatus, setStateStatus] = useState("");


  const [isMinLoaading, setisMinLoading] = useState(true);

  const accountSetup = async () => {
    //setart loader
    const account = await providerHandler();
    const whitelist = await getSignerWhiteList(account);
    const reserveList = await getSignerReserveList(account);

    console.log(whitelist, reserveList, "signature");

    console.log(await isWhiteList(),'whitelistcheck')
    console.log(await isReserveList(),'reservelistcheck')
    console.log(await isPublicList(),'publiccheck')

    if(await isWhiteList()){
      console.log('Inside Whitelist')
      if(whitelist){
        setListType(1)
        setMaxCount(WHITE_MINT_MAX);
        setMintButton(false)
        setSigner(whitelist.signature);
      }
    } else if(await isReserveList()){
      console.log('Inside ReserveList')
      if(reserveList){
        setListType(2)
        setMaxCount(RESERVE_MINT_MAX);
        setMintButton(false)
        setSigner(reserveList.signature);
      }

    }else if(await isPublicList()){
      console.log('Inside PublicList')
        setListType(0)
        setMaxCount(PUBLIC_MINT_MAX);
        setMintButton(false)
    }
    else{
      console.log('In else')
    }
   



    // if (whitelist) {
    //   if (whitelistCapCheck()) {
    //     setListType(0);
    //     setMaxCount(2);
    //   } else {
    //     setListType(1);
    //     setMaxCount(2);
    //     setSigner(whitelist.signature);
    //   }
    // } else if (reserveList) {
    //   if (reservelistCapCheck()) {
    //     setMaxCount(1);
    //     setListType(0);
    //   } else {
    //     setListType(2);
    //     setMaxCount(1);
    //     setSigner(reserveList.signature);
    //   }
    // } else {
    //   setMaxCount(2);
    // }
    setisMinLoading(false)
    //end loader
  };

  useEffect(() => {
    accountSetup();
  }, [account]);

  useEffect(() => {
    setStateStatus(status);
  }, [status]);

  useEffect(() => {
    async function listenMMAccount() {
      window.ethereum.on("accountsChanged", async function () {
        window.location.reload();
      });
    }
    listenMMAccount();
  }, []);

  useEffect(() => {
    if (mintCount > 0) {
      if (listType === 1) {
        setTotalMinCount((mintCount * WHITE_LIST_MINT).toFixed(3));
      } else if (listType === 2) {
        setTotalMinCount((mintCount * RESERVE_MINT).toFixed(3));
      } else {
        setTotalMinCount((mintCount * PUBLIC_MINT).toFixed(3));
      }
    } else {
      setTotalMinCount(0.0);
    }
  }, [mintCount]);
  const onMintDecress = () => {
    if (mintCount > 0) {
      setCountCheck(mintCount - 1);
      setMintCount(mintCount - 1);
    }
  };
  const onMintIncress = () => {
    setCountCheck(mintCount + 1);
    if (mintCount < maxCount) {
      setMintCount(mintCount + 1);
    }
    if (countCheck >= maxCount) {
      console.log("max count", maxCount);
      console.log("check count", countCheck);
      // setDisable(true);
      //toast.error("Please wait for mint to load");
      setCountCheck(2);
    }
  };

  const [isMint, setIsMint] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const currentTime = (Date.now() / 1000).toFixed();

  // useEffect(() => {
  //   const disableCheck = async () => {
  //     const WEndTime = await whiteListEndTime();
  //     const REndTime = await reserveListEndTime();
  //     if (WEndTime < currentTime && listType === 2) {
  //       setDisable(true);
  //     } else if (REndTime < currentTime && listType !== 0 ) {
  //       setDisable(true);
  //     }
  //   }
  //   disableCheck()
  // }, []);

  // useEffect(() => {
  //   if (listType === 1) {
  //     async function whiteListEndTimeCheck() {
  //       const WEndTime = await whiteListEndTime();
  //       if (WEndTime > currentTime) {
  //         setDisable(true);
  //         toast.error("whitelist minting time has not ended");
  //       }
  //     }
  //     whiteListEndTimeCheck();
  //   } else if (listType === 2) {
  //     async function reservedListEndTimeCheck() {
  //       const reservedListStartTime = await whiteListEndTime();
  //       console.log("reserve s time", reservedListStartTime);

  //       const REndTime = await reserveListEndTime();
  //       if (reservedListStartTime < currentTime) {
  //         setDisable(true);
  //         toast.error("Reserved wallet minting time has not started");
  //       }
  //       // if (REndTime < currentTime) {
  //       //   setDisable(true)
  //       //   toast.error("Reserved wallet minting time has not ended");
  //       //   console.log("rend time", REndTime);
  //       // }
  //     }
  //     reservedListEndTimeCheck();
  //   }
  //   // if (listType !== 1 && listType !== 2)
  //   else {
  //     async function publicCheck() {
  //       const publicStartTime = await reserveListEndTime();
  //       if (publicStartTime < currentTime) {
  //         console.log("public s time", publicStartTime);
  //         toast.error("Public minting time has not started");
  //       }
  //     }
  //     publicCheck();
  //   }
  // }, [mintCount]);
  console.log("list type", listType);
  const onMintClick = async () => {
    console.log("minting");
    setIsMint(false);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
    let result;
    if (listType === 2) {
      console.log('reserve mint')
      result = await reserveListMint(mintCount, signer);
    } else if (listType === 1) {
      console.log('whitelist mint')
      result = await whiteListMint(mintCount, signer);
    } else {
      console.log('public mint')
      result = await publicMint(mintCount);
    }
    console.log(result,'result')
    if (!result) {
      setIsMint(true);
      return;
    } else {
      setIsLoading(false);
      setUrl(`https://etherscan.io/tx/${result.hash}`);
    }
  };

  if (status === "initializing") {
    return (
      <div className="tomodachi">
        <PropagateLoader color="rgb(54, 215, 183)" loading={true} size={20} />
      </div>
    );
  }
  return (
    <div className="tomodachi">
      {status !== "connected" ? (
        <>
          <Banner bannerImage={BannerImage} />
          <ConnectButton status={stateStatus} toast={toast} connect={connect} />
        </>
      ) : (
        <>
          {isMint ? (

            <>
              <Banner bannerImage={BannerImage} />
              <AccountDetails account={accountName} />
              {isMinLoaading ? (<div style={{marginTop:"20px"}}>
                <PropagateLoader
                  color="rgb(54, 215, 183)"
                  loading={true}
                  size={20}
                />
                </div>
              ) : (
                <Mint
                  onMintDecress={() => onMintDecress()}
                  mintCount={mintCount}
                  maxCount={maxCount}
                  countCheck={countCheck}
                  totalMinCount={totalMinCount}
                  onMintClick={() => onMintClick()}
                  onMintIncress={() => onMintIncress()}
                  disable={mintButton}
                />
              )}
            </>

          ) : (
            <>
              <Banner bannerImage={isLoading ? PendingImage : SuccessImage} />
              <AccountDetails account={accountName} />
              <PendingTransaction
                transactionStatus={isLoading}
                etherscanUrl={url}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default Home;
