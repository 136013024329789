import { ethers } from "ethers"
import tomoAbi from "./abi.json"
import { toast } from 'react-toastify'
import moment from 'moment';
let tomoContract, address

const tomoAddress = "0x58DcEdfB8291B2DA55C523d3d9Ef90409c1535Ef"; //tomodachi contract

export let PUBLIC_MINT
export let RESERVE_MINT
export let WHITE_LIST_MINT

export let PUBLIC_MINT_MAX
export let RESERVE_MINT_MAX
export let WHITE_MINT_MAX


let whiteListEnd, reserveListEnd

export const providerHandler = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    address = account[0];
    const signer = provider.getSigner();
    tomoContract = new ethers.Contract(tomoAddress, tomoAbi, signer);
    console.log("tomo contract", tomoContract)
    await setPrice()
    await settime()
    console.log("contracts init done", PUBLIC_MINT, RESERVE_MINT, WHITE_LIST_MINT);
    return address;
};

export const setPrice = async () => {
    console.log('getting price')
    const a = await tomoContract.whiteListPriceForEach()
    const b = await tomoContract.reserveListPriceForEach()
    const c = await tomoContract.publicMintPriceForEach()
    console.log("price", ethers.utils.formatEther(a), ethers.utils.formatEther(b), ethers.utils.formatEther(c))
    PUBLIC_MINT = ethers.utils.formatEther(c)
    RESERVE_MINT = ethers.utils.formatEther(b)
    WHITE_LIST_MINT = ethers.utils.formatEther(a)
    const d = await tomoContract.maxPublicMintForEach()
    const e = await tomoContract.maxReserveMintForEach()
    const f = await tomoContract.maxWhiteListMintForEach()
    PUBLIC_MINT_MAX = d
    RESERVE_MINT_MAX = e
    WHITE_MINT_MAX = f
}

//write Functions

export const publicMint = async (amount) => {
    try {
        toast.loading('Minting....')
        // if (amount > 2) {
        //     return false
        // } //Mint Blocker
        const n = await tomoContract.publicMint(amount, {
            value: (amount * ethers.utils.parseEther(`${PUBLIC_MINT}`)).toString(),
        })
        await n.wait()
        toast.dismiss()
        toast.success('Minted!')
        console.log(n)
        return n;

    } catch (e) {
        toast.dismiss()
        toast.error(e.code === "INSUFFICIENT_FUNDS" ? "Wallet does not have enough balance" : (e.code === "UNPREDICTABLE_GAS_LIMIT" ? "Wallet not eligible to make this transaction" : e.message))
        console.log(e);
        return false
    }
}

export const reserveListMint = async (amount, signature) => {
    try {
        console.log(amount,signature,'ReserveList Mint')
  
        toast.loading('Minting....')
        // if (amount > 1) {
        //     return false
        // } //Mint Blocker
        const n = await tomoContract.reserveListMint([address, true, signature], amount,{
            value: (amount * ethers.utils.parseEther(`${RESERVE_MINT}`)).toString(),
        })
        await n.wait()
        toast.dismiss()
        toast.success('Minted!')
        console.log(n)
        return n;

    } catch (e) {
        toast.dismiss()
        toast.error(e.code === "INSUFFICIENT_FUNDS" ? "Wallet does not have enough balance" : (e.code === "UNPREDICTABLE_GAS_LIMIT" ? "Wallet not eligible to make this transaction" : e.message))
        console.log(e.code);
        return false
    }
}

export const whiteListMint = async (amount, signature) => {
    try {
        console.log(amount, signature)
        toast.loading('Minting....')
        // if (amount > 2) {
        //     return false
        // } //Mint Blocker
        const n = await tomoContract.whiteListMint([address, false, signature], amount, {
            value: (amount * ethers.utils.parseEther(`${WHITE_LIST_MINT}`)).toString(),
        })
        await n.wait()
        toast.dismiss()
        toast.success('Minted!')

        console.log(n)
        return n;

    } catch (e) {
        console.log(e.message)
        toast.dismiss()
        toast.error(e.code === "INSUFFICIENT_FUNDS" ? "Wallet does not have enough balance" : (e.code === "UNPREDICTABLE_GAS_LIMIT" ? "Wallet not eligible to make this transaction" : e.message))
        return false
    }
}


//function to get the number of token a user have
export const totalUnstakedTokens = async () => {
    const nftCount = await tomoContract.balanceOf(address);
    console.log("nftCount", nftCount.toNumber());
    if (nftCount.toNumber() === 0) {
        return [];
    } else {
        let nft_list = [];
        for (let i = 0; i < nftCount.toNumber(); i++) {
            const n = await tomoContract.tokenOfOwnerByIndex(address, i);
            nft_list.push(n.toNumber());
        }
        console.log(nft_list);
        return nft_list;
    }
};

export const whiteListEndTime = async () => {
    const n = await tomoContract.whiteListEndTime()
    return n.toNumber();
}

export const reserveListEndTime = async () => {
    const n = await tomoContract.reserveListEndTime()
    return n.toNumber();
}


export const isWhiteList = async () => {
    const currentTime = moment().unix()
    console.log(whiteListEnd,currentTime,'whiteEnd')
    if(currentTime<whiteListEnd) {
        const whiteCap = await whiteListCap()
        const whiteMint = await whiteListMintedd()
        if(whiteMint<whiteCap){
            return true
        }
        else{
            return false
        }
    }else{
        return false
    }
}

export const isReserveList = async () => {
    const currentTime = moment().unix()
    console.log(whiteListEnd,currentTime,reserveListEnd,'reservend')
    if(currentTime>=whiteListEnd && currentTime<reserveListEnd) {
        console.log('inside reserve')
        const whiteCap = await whiteListCap()
        const whiteMint = await whiteListMintedd()
        const reserveMint = await reserveListMintedd()
        console.log(whiteCap,whiteMint,reserveMint,'values of mint')
        if(whiteMint+reserveMint<whiteCap){
            return true
        }
        else{
            return false
        }
    }
    else{
        return false
    }
}

export const isPublicList = async () => {
    const currentTime = moment().unix()
    const whiteCap = await whiteListCap()
    const whiteMint = await whiteListMintedd()
    const reserveMint = await reserveListMintedd()
    console.log(whiteListEnd,currentTime,reserveListEnd,'public')
    if(whiteCap==whiteMint && currentTime>whiteListEnd){
        return true
    }else if(currentTime>reserveListEnd){
        return true
    }
    else{
        return false
    }
}

const settime = async()=> {
    whiteListEnd = await whiteListEndTime()
    reserveListEnd = await reserveListEndTime()
   
}

// const m = await tomoContract.whiteListCap()
// const o = await tomoContract.whiteListMinted()
// toast.success('Minted! m', m)
// toast.success('Minted! o', o)
// toast.success('Minted!')

export const reserveListCap = async () => {
    const n = await tomoContract.reserveListCap()
    console.log("cap", n)
    return n.toNumber();
}
export const reserveListMintedd = async () => {
    const n = await tomoContract.reserveListMinted()
    console.log("cap", n)
    return n.toNumber();
}
export const whiteListCap = async () => {
    const n = await tomoContract.whiteListCap()
    console.log("cap", n.toNumber())
    return n.toNumber();
}
export const whiteListMintedd = async () => {
    const n = await tomoContract.whiteListMinted()
    console.log("capmin", n.toNumber())
    return n.toNumber();
}